'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './audio.module.sass';
import 'swiper/css';
import { AudioPlayer } from './components/AudioPlayer';
import audioArrow from '/public/audioArrow.svg';
import Image from 'next/image';
import audioArrowLeft from '/public/audioArrowLeft.svg';

export const Audio: React.FC = () => {
	const [pages, setPages] = useState<any[]>([]);
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
	const swiperRef = useRef<any>(null);

	useEffect(() => {
		JsAPI.Dao.getNews(
			{
				block_id: 100839,
				count: 5,
				fields: 65535
			},
			function (payload) {
				setPages(payload);
			}
		);
	}, []);

	return (
		<>
			<section className={style.Navigation}>
				{currentSlideIndex == 0 ? (
					<></>
				) : (
					<div
						onClick={() => swiperRef.current?.slidePrev()}
						className={style.Arrowleft}
					>
						<Image src={audioArrowLeft} alt='audioArrowLeft' />
					</div>
				)}

				<Swiper
					spaceBetween={50}
					slidesPerView={1}
					simulateTouch={false}
					onSlideChange={swiper => {
						setCurrentSlideIndex(swiper.activeIndex);
					}}
					onSwiper={swiper => {
						swiperRef.current = swiper;
					}}
				>
					{pages.length === 0 ? (
						<section className={style.WrapperLoading}></section>
					) : (
						pages.map((el: any, index: number) => (
							<SwiperSlide key={el.id}>
								<AudioPlayer
									el={el}
									index={index}
									currentSlideIndex={currentSlideIndex}
								/>
							</SwiperSlide>
						))
					)}
				</Swiper>
				{currentSlideIndex == pages.length - 1 ? (
					<></>
				) : (
					<div
						onClick={() => swiperRef.current?.slideNext()}
						className={style.ArrowRight}
					>
						<Image src={audioArrow} alt='audioArrow' />
					</div>
				)}
			</section>
		</>
	);
};
