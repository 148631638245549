'use client';
import React, { useCallback, useState } from 'react';
import style from '../audio.module.sass';
import { Slider } from '@mui/material';
import { IconButton } from '@mui/material';
import Image from 'next/image';
import { useAppSelector } from '@/Hooks/redux';

interface VolumeHandlerProps {
	handleVolumeChange: (value: number) => void;
}

const VolumeHandlerComponent: React.FC<VolumeHandlerProps> = ({
	handleVolumeChange
}) => {
	const { volume } = useAppSelector(state => state.HeaderState);
	const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);
	const handleMouseEnter = useCallback(() => {
		setShowVolumeSlider(true);
	}, [setShowVolumeSlider]);

	const handleMouseLeave = useCallback(() => {
		setShowVolumeSlider(false);
	}, [setShowVolumeSlider]);

	return (
		<div
			className={style.VolumeControl}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<IconButton>
				<Image width={20} src={'/VolumeFull.svg'} height={16} alt='volume' />
			</IconButton>

			{showVolumeSlider && (
				<div className={style.VolumeSliderWrapper}>
					<Slider
						orientation='vertical'
						value={volume}
						min={0}
						max={1}
						step={0.01}
						onChange={(_, value) => {
							handleVolumeChange(value as number);
						}}
						sx={{
							color: 'black',
							height: 70,
							'& .MuiSlider-thumb': {
								backgroundColor: 'black',
								width: 15,
								height: 15,
								boxShadow: 'none',
								'&:hover': {
									boxShadow: 'none',
									WebkitBoxShadow: 'none'
								},
								'&:focus, &:active': {
									boxShadow: 'none',
									WebkitBoxShadow: 'none'
								}
							},
							'& .Mui-focusVisible': {
								boxShadow: 'none !important',
								WebkitBoxShadow: 'none !important'
							},
							'& .MuiSlider-thumb.Mui-active': {
								boxShadow: 'none '
							}
						}}
					/>
				</div>
			)}
		</div>
	);
};

export const VolumeHandler = React.memo(VolumeHandlerComponent);
