import React from 'react';
import Image from 'next/image';
import style from '../audio.module.sass';

const AudioNameComponent = ({ el }: any) => {
	return (
		<div className={style.AudioName}>
			{el.image ? (
				<Image
					width={60}
					height={60}
					src={el.image}
					alt='logo'
					style={{ borderRadius: '8px' }}
				/>
			) : (
				<></>
			)}
			<div
				className={style.AudioDescription}
				style={{
					fontSize: '16px',
					paddingLeft: 8
				}}
			>
				{el.title}
			</div>
		</div>
	);
};

export const AudioName = React.memo(AudioNameComponent);
