'use client';

import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { useAppSelector } from '@/Hooks/redux';
import { IconButton } from '@mui/material';
import Image from 'next/image';
import style from '../audio.module.sass';
import { useDispatch } from 'react-redux';
import { HeaderState } from '@/Redux/Slices/HeaderSlice/headerState';
import { AudioName } from './AudioName';
import { TimeLine } from './TimeLine';
import { VolumeHandler } from './VolumeHandler';
import audioTitle from '/public/audioTitle.svg';

type PlayerRefType = ReactPlayer & {
	seekTo: (seconds: number, type?: 'seconds' | 'fraction') => void;
};
interface ElType {
	author: string;
	comments: number;
	date: string;
	date_milliseconds: number;
	domain: string;
	id: number;
	image: string;
	logo: string;
	original_url: string;
	partner_type: string;
	shares: number;
	text: string;
	title: string;
	topic_id: number;
	topic_name: string;
	trackers: {
		RENDER: any[];
		VIEWABILITY: any[];
		CLICK: any[];
	};
	type: string;
	url: string;
	viewability: {
		news_id: number;
		article_id: number;
		block_id: number;
		strategy_id: number;
		source_id: number;
	};
	votes: number;
	weight: number;
}

export const AudioPlayer = ({
	el,
	index,
	currentSlideIndex
}: {
	el: ElType;
	index: number;
	currentSlideIndex: number;
}) => {
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const { volume } = useAppSelector(state => state.HeaderState);
	const dispatch = useDispatch();
	const [playing, setPlaying] = useState<boolean>(false);
	const [playedSeconds, setPlayedSeconds] = useState<number>(0);
	const [duration, setDuration] = useState<number>(0);
	const [playbackRate, setPlaybackRate] = useState<number>(1);
	const playerRef = useRef<PlayerRefType>(null);
	const { SetVolume } = HeaderState.actions;

	useEffect(() => {
		setPlaying(false);
	}, [currentSlideIndex]);

	const handlePlayPause = () => {
		setPlaying(prev => !prev);
	};

	const handleVolumeChange = useCallback(
		(value: number) => {
			dispatch(SetVolume(value));
		},
		[dispatch]
	);

	const handleSeek = (
		event: React.SyntheticEvent | Event,
		newValue: number | number[]
	) => {
		if (playerRef.current) {
			playerRef.current.seekTo(newValue as number);
		}
	};

	const handleRewind = () => {
		if (playerRef.current) {
			playerRef.current.seekTo(playedSeconds - 15, 'seconds');
		}
	};

	const handleFastForward = () => {
		if (playerRef.current) {
			playerRef.current.seekTo(playedSeconds + 15, 'seconds');
		}
	};

	const handlePlaybackRateChange = () => {
		const newRate = playbackRate === 2 ? 1 : playbackRate + 0.5;
		setPlaybackRate(newRate);
	};

	return (
		<section className={style.WrapperOfWrapper} key={el.id}>
			<section className={style.Wrapper}>
				<div className={style.AudioTitle}>
					<Image src={audioTitle} alt='audioTitle' />
					<h4
						style={{
							fontSize: `${13 + CurrentFontSize}px`
						}}
					>
						Аудио дня
					</h4>
				</div>
				<div className={style.AudioBody}>
					<AudioName el={el} />
					<ReactPlayer
						ref={playerRef}
						url={el.text}
						playing={playing}
						volume={volume}
						playbackRate={playbackRate}
						onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
						onDuration={setDuration}
						width='0'
						height='0'
					/>
					<TimeLine
						playedSeconds={playedSeconds}
						setPlayedSeconds={setPlayedSeconds}
						duration={duration}
						handleSeek={handleSeek}
					/>
					<div className={style.PlayerProperties}>
						<VolumeHandler handleVolumeChange={handleVolumeChange} />

						<IconButton onClick={handleRewind}>
							<Image
								width={24}
								src={'/rewind-15.svg'}
								height={24}
								alt='rewind'
							/>
						</IconButton>

						<IconButton onClick={handlePlayPause}>
							<div
								style={{
									width: '44px',
									height: '44px',
									borderRadius: '50%',
									border: '2px solid black',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								{playing ? (
									<Image src='/pause.png' width={14} height={18} alt='pause' />
								) : (
									<Image src='/playBtn.svg' width={17} height={18} alt='play' />
								)}
							</div>
						</IconButton>

						<IconButton onClick={handleFastForward}>
							<Image
								width={24}
								src={'/rewind+15.svg'}
								height={24}
								alt='fast forward'
							/>
						</IconButton>

						<IconButton onClick={handlePlaybackRateChange}>
							<Image
								width={30}
								height={30}
								src={`/Speed=${playbackRate}.svg`}
								alt={`Speed ${playbackRate}x`}
							/>
						</IconButton>
					</div>
				</div>
			</section>
		</section>
	);
};
