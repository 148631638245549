'use client';
import React, { useMemo } from 'react';
import style from '../audio.module.sass';
import { Slider } from '@mui/material';

interface TimeLineProps {
	playedSeconds: number;
	setPlayedSeconds: (seconds: number) => void;
	duration: number;
	handleSeek: (
		event: React.SyntheticEvent | Event,
		newValue: number | number[]
	) => void;
}

const TimeLineComponent = ({
	playedSeconds,
	setPlayedSeconds,
	duration,
	handleSeek
}: TimeLineProps) => {
	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = Math.floor(seconds % 60);
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	return useMemo(
		() => (
			<div className={style.TimelineWrapper}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: '10px',
						width: '90%'
					}}
				>
					<span className={style.Timeline}>{formatTime(playedSeconds)}</span>

					<Slider
						className={'swiper-no-swiping'}
						value={playedSeconds}
						min={0}
						max={duration}
						onChange={(_, seconds) => setPlayedSeconds(seconds as number)}
						onChangeCommitted={handleSeek}
						sx={{
							color: '#ccc',
							width: '80%',
							'& .MuiSlider-thumb': {
								backgroundColor: '#45AD4E',
								width: 10,
								height: 10,
								boxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2)',
								'&:hover': {
									boxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2)',
									WebkitBoxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2)'
								},
								'&:focus, &:active': {
									boxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2)',
									WebkitBoxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2)'
								}
							},
							'& .Mui-focusVisible': {
								boxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2) !important',
								WebkitBoxShadow:
									'0px 0px 0px 8px rgba(69, 173, 78, 0.2) !important'
							},
							'& .MuiSlider-thumb.Mui-active': {
								boxShadow: '0px 0px 0px 8px rgba(69, 173, 78, 0.2) '
							}
						}}
						style={{ flex: 1, margin: '0 10px' }}
					/>

					<span className={style.Timeline}>
						{formatTime(duration - playedSeconds)}
					</span>
				</div>
			</div>
		),
		[playedSeconds, duration, handleSeek, setPlayedSeconds]
	);
};

export const TimeLine = React.memo(TimeLineComponent);
